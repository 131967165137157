<template>
  <section class="relative">
    <div class="bg-cover bg-center bg-gray-500 h-3/6" :style="backgroundImage">
  <div class="container mx-auto py-24 px-4 flex flex-col-reverse md:flex-row">
    <div class="w-full md:w-1/2 flex flex-col justify-center md:pr-8">
      <!-- Column 1: Logo -->
      <img src="@/assets/img/home/blink_logo.png" alt="Blink Laser Tattoo Removal" class="w-3/4" />
    </div>
    <div class="w-full md:w-1/2 flex flex-col justify-center text-white">
      <!-- Column 2: Text and CTA -->
      <h1 class="text-4xl font-bold mb-4">
        PROFESSIONAL LASER TECHNICIANS TO ASSIST IN YOUR TATTOO REMOVAL JOURNEY.
      </h1>
      <p class="text-xl mb-6">
        BOOK YOUR COMPLIMENTARY CONSULTATION TODAY.
      </p>
      <router-link to="/contact-us" class="bg-brand-primary text-white font-bold py-3 px-6 w-64 rounded">
        BOOK A CONSULTATION
      </router-link>
    </div>
  </div>
</div>
  </section>

  <section class="mx-auto py-12 bg-brand-secondary">
  <div class="flex flex-col md:flex-row">
    <div class="w-full md:w-1/2">
      <!-- Column 1: Image -->
      <img src="@/assets/img/home/home_hero.png" alt="About Blink Laser Tattoo Removal" class="w-full h-auto rounded-md" />
    </div>
    <div class="w-full md:w-1/2 md:px-8 flex flex-col justify-center p-5">
      <!-- Column 2: Text -->
      <h1 class="text-2xl md:text-4xl font-bold mb-4">
        ABOUT US
      </h1>
      <p class="text-base md:text-lg leading-relaxed">
        Many people get tattoos on impulse or without fully considering the long-term effects of having a permanent design on their skin. As time passes, their tastes and preferences may change, leaving them with a tattoo that no longer represents who they are. 
        In some cases, tattoos may even negatively impact career opportunities or personal relationships. This is where we step in, our professional and friendly technicians have a passion to help people feel comfortable in their own skin. 
        We understand that sometimes we need a do-over and we would love to assist you in making that hope a reality, whether to cover it up with a new beautiful design or just keep it bare.
      </p>
    </div>
  </div>
</section>


<section class="container mx-auto px-4 py-12 items-center text-center justify-items-center">
 
  <div class="grid grid-cols-1 md:grid-cols-3 gap-8 items-stretch text-center">
    <!-- Column 1 -->
    <div class="flex flex-col h-full items-center text-center">
      <div class="h-full max-w-sm p-6 bg-brand-secondary border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 items-center text-center">
        
        <a href="#">
          <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Who we are?</h5>
        </a>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Blink is a team of fully trained professional laser technicians. We dedicate our working hours to helping you remove tattoos. 
          Whether it's a tidy-up before a cover-up, a straight removal, or a fade away, we'll help you remove the ink for good.</p>
      </div>
    </div>
    <!-- Column 2 -->
    <div class="flex flex-col h-full items-center text-center">
      <div class="h-full max-w-sm p-6 bg-brand-secondary border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 items-center text-center">
       
        <a href="#">
          <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">What we do?</h5>
        </a>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Using a state of the art laser specifically designed to remove tattoos, we gradually remove the ink until there is none left! 
          This may take several or many sessions - but after each one, you will see a difference.</p>
      </div>
    </div>
    <!-- Column 3 -->
    <div class="flex flex-col h-full items-center text-center">
      <div class="h-full max-w-sm p-6 bg-brand-secondary border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 items-center text-center">
      
        <a href="#">
          <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">How we do it?</h5>
        </a>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Our laser, using controlled light and heat, fragments your existing ink into micro particles, allowing your body to eat it up and dispose of it. It's that simple!</p>
      </div>
    </div>
  </div>
  <router-link to="/contact-us" class="bg-brand-primary text-white font-bold py-3 px-6 w-64 rounded mt-8">
    BOOK A CONSULTATION
  </router-link>
</section>


  
</template>

<script>
export default {
  name: 'HomeView',
  computed: {
    backgroundImage() {
      return `background-image: url('${require('@/assets/img/home/home_hero.png')}')`;
    },
  },
};
</script>
