<template>
  <header class="bg-brand-header shadow-md py-4">
    <div class="container mx-auto px-4 flex justify-between items-center">
      <div>
        <!-- Logo -->
        <a href="/" class="">
          <img src="@/assets/img/home/blink_logo.png" alt="Blink Logo" class="h-24" />
        </a>
      </div>
      <nav class="hidden md:block">
        <!-- Main navigation -->
        <ul class="flex space-x-6">
          <li>
            <router-link to="/" class="text-white hover:text-brand-primary">
              Home
            </router-link>
          </li>
          <li>
            <router-link to="/faq" class="text-white hover:text-brand-primary">
              FAQ
            </router-link>
          </li>
          <li>
            <router-link to="/contact-us" class="text-white hover:text-brand-primary">
              Contact Us
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- Mobile menu icon -->
      <div class="md:hidden">
  <button type="button" @click="showMenu = !showMenu" class="text-white hover:text-brand-primary focus:outline-none">
    <svg class="w-6 h-6 bg-brand-primary" viewBox="0 0 24 24" >
      <path v-if="showMenu" d="M6 18L18 6M6 6l12 12"></path>
      <path v-else d="M4 6h16M4 12h16M4 18h16"></path>
    </svg>
  </button>
</div>
      <!-- Mobile menu -->
      <div class="absolute top-0 left-0 w-full md:hidden" :class="{ 'hidden': !showMenu }">
        <ul class="bg-brand-header p-4">
          <li class="my-2">
            <router-link to="/" class="text-white hover:text-brand-primary">
              Home
            </router-link>
          </li>
          <li class="my-2">
            <router-link to="/faq" class="text-white hover:text-brand-primary">
              FAQ
            </router-link>
          </li>
          <li class="my-2">
            <router-link to="/contact-us" class="text-white hover:text-brand-primary">
              Contact Us
            </router-link>
          </li>
        </ul>
      </div>
      <!-- Optional: Call-to-action button or social media icons -->
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>

<style scoped>
  /* Header component styles go here */
</style>
