import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';
import Flowbite from 'flowbite';
import { Accordion, AccordionItem } from 'flowbite';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


const app = createApp(App);
app.use(Flowbite);
library.add(faFacebookF, faInstagram, faTiktok);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('Accordion', Accordion);
app.component('AccordionItem', AccordionItem);
app.use(router);
app.mount('#app');
