<template>
    <div>
        <!-- Hero Section -->
        <div class="bg-cover bg-center grid place-content-center	 align-middle bg-gray-500" :style="backgroundImage"
            style="height: 50vh;">


            <H1 class="text-white text-4xl font-bold">CONTACT US</H1>

        </div>

        <!-- Studio Sections -->
        <section class="container-fluid mx-auto px-8 py-12 bg-brand-secondary">
            <!-- Blink Claremont -->
            <div class="mb-16 ">
                <h2 class="text-2xl md:text-3xl font-bold mb-4">
                    BLINK CLAREMONT
                </h2>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <!-- Map (Replace with an actual map) -->
                        <div class="">
                            <iframe class="w-full aspect-video"
                                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3308.58618729455!2d18.463255915213118!3d-33.977474180626366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDU4JzM4LjkiUyAxOMKwMjcnNTUuNiJF!5e0!3m2!1sen!2sza!4v1683728454047!5m2!1sen!2sza"
                                style="border:0;" allowfullscreen loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>

                                
                        </div>
                    </div>
                    <div>
                        <address class="text-lg">
                            UNIT 1 B<br>
                            FIRST FLOOR<br>
                            MONTCLARE PLACE<br>
                            CLAREMONT CAPE TOWN, CLAREMONT, 7708<br>
                            <br>
                            TELEPHONE: 072 957 9736<br>
                            EMAIL: claremont@blinksa.co.za
                        </address>
                    </div>
                </div>
            </div>
        </section>
        <!-- Blink Benoni -->
        <section class="container-fluid mx-auto px-8 py-12 ">
            <div class="mb-16 ">
                <h2 class="text-2xl md:text-3xl font-bold mb-4">
                    BLINK BENONI
                </h2>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <!-- Map (Replace with an actual map) -->
                        <div class="  ">
                            <iframe class="w-full aspect-video"
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14322.204422453971!2d28.2865618!3d-26.1787451!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9517b1582341dd%3A0xbeb15c7317e6bd8c!2sBare%20-%20Laser%20Hair%20Removal%20Specialists%20Benoni!5e0!3m2!1sen!2sza!4v1681979347002!5m2!1sen!2sza"
                                style="border:0;" allowfullscreen loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                    <div>
                        <address class="text-lg">
                            SHOP 17 B<br>
                            THE CONSERVATORY © LAKEFELD<br>
                            106 LAKEFELD AVENUE,<br>
                            LAKESIDE, BENONI, 1501<br>
                            <br>
                            TELEPHONE: 082 525 2106<br>
                            EMAIL: benoni@blinksa.co.za
                        </address>
                    </div>
                </div>
            </div>
        </section>

        <!-- Contact Form Section -->
        <section class="container-fluid  px-4 py-12 bg-brand-secondary">
            <div class="container mx-auto">


                <form method="post" action="/api/contact_form.php">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label for="name" class="block mb-2">Name</label>
                            <input type="text" id="name" name="name" class="w-full border border-gray-300 p-2" />
                        </div>
                        <div>
                            <label for="surname" class="block mb-2">Surname</label>
                            <input type="text" id="surname" name="surname" class="w-full border border-gray-300 p-2" />
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                        <div>
                            <label for="phone" class="block mb-2">Phone Number</label>
                            <input type="tel" id="phone" name="phone" class="w-full border border-gray-300 p-2" />
                        </div>
                        <div>
                            <label for="email" class="block mb-2">Email Address</label>
                            <input type="email" id="email" name="email" class="w-full border border-gray-300 p-2" />
                        </div>
                    </div>
                    <div class="mt-6">
                        <label for="studio" class="block mb-2">Select Studio</label>
                        <select id="studio" name="studio" class="w-full border border-gray-300 p-2">
                            <option>BLINK CLAREMONT</option>
                            <option>BLINK BENONI</option>
                        </select>
                    </div>
                    <div class="mt-6">
                        <label for="date" class="block mb-2">Preferred Consultation Date</label>
                        <input type="date" id="date" name="date" class="w-full border border-gray-300 p-2" />
                    </div>
                    <div class="mt-6">
                        <label for="message" class="block mb-2">Message</label>
                        <textarea id="message" name="message" rows="4" class="w-full border border-gray-300 p-2"></textarea>
                    </div>
                    <div class="mt-6">
                        <button type="submit" class="bg-brand-primary text-white font-bold py-3 px-6 w-64 rounded">
                            Submit
                        </button>
                    </div>
                    <div v-if="statusMessage" class="text-center my-4">
                        {{ statusMessage }}
                    </div>
                </form>
            </div>
        </section>

    </div>
</template>
<script>
export default {
    name: 'ContactUsView',
    computed: {
        backgroundImage() {
            return `background-image: url('${require('@/assets/img/contact/contact_hero.png')}')`;
        },
    },
    data() {
        return {
            statusMessage: '',
        };
    },
    created() {
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('status');

        if (status === 'success') {
            this.statusMessage = 'Email sent successfully!';
        } else if (status === 'error') {
            this.statusMessage = 'There was an error sending the email. Please try again.';
        } else if (status === 'invalid_email') {
            this.statusMessage = 'Invalid email address. Please check and try again.';
        }
    },
};
</script>