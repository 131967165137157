<template>
  <footer class="bg-brand-header  shadow dark:bg-gray-900">
    <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
      <div class="sm:flex sm:items-center sm:justify-between">
        <a href="#" class="flex items-center mb-4 sm:mb-0">
          <img src="@/assets/img/home/blink_logo.png" alt="Blink Logo" class="h-24" />

        </a>
        <!-- Social Icons -->
        <div>
          <a href="https://www.facebook.com/profile.php?id=100092492781208&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer"
            class="text-white hover:text-blue-500 mx-2">
            <font-awesome-icon :icon="['fab', 'facebook-f']" />
          </a>
          <a href="https://www.instagram.com/blinktattooremoval/" target="_blank" rel="noopener noreferrer"
            class="text-white hover:text-pink-500 mx-2">
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
          

        </div>

      </div>
      <hr class="my-6 border-brand-primary sm:mx-auto dark:border-gray-700 lg:my-8" />
      <span class="block text-sm text-white sm:text-center dark:text-gray-400">© {{ currentYear }}  <a href="#"
          class="hover:underline">Blink</a>. All Rights Reserved.</span>
          <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400"><a href="#"
          class="hover:underline">Developed by Fun Things</a></span>
    </div>
    
  </footer>
  
</template>
  
<script>
export default {
  name: 'Footer',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};

</script>
  
  