import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ContactUsView from '../views/ContactUsView.vue';
import FAQView from '../views/FAQView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUsView,
  },
  {
    path: '/faq',
    path: '/faq',
    name: 'FAQ',
    component: FAQView,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
