<template>
    <div>
        <section class="relative h-[50vh]">
            <img class="w-full h-full object-cover" src="@/assets/img/faq/faq_hero.png" alt="FAQ Hero Background" />
            <div class="absolute inset-0 flex flex-col justify-center items-center text-end text-white">
                <h1 class="text-4xl sm:text-5xl md:text-6xl font-bold ">FREQUENTLY ASKED QUESTIONS</h1>

            </div>
        </section>

        <!-- FAQ Section -->
        <section class=" bg-brand-secondary">

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div class=" p-4">

                    <div class="max-w-2xl mx-auto p-16 rounded">


                        <div>

                            <!-- FQA 1 -->
                            <AccordionItem title="How does laser tattoo removal work?">
                                <p>
                                    Laser tattoo removal uses highly concentrated light energy to break down the ink
                                    particles in the tattoo. The fragmented ink particles are then naturally absorbed
                                    and eliminated by the body's immune system.
                                </p>
                            </AccordionItem>
                            <!-- FQA 2 -->
                            <AccordionItem title="Is laser tattoo removal painful?">
                                <p>
                                    While laser tattoo removal can cause some discomfort, most patients compare the
                                    sensation to that of a rubber band snapping against the skin. Numbing creams or
                                    local anesthesia can be used to minimize discomfort during the procedure.
                                </p>
                            </AccordionItem>
                            <!-- FQA 3 -->
                            <AccordionItem title="How many sessions will I need to remove my tattoo?">
                                <p>
                                    The number of sessions needed varies depending on factors such as the size, color,
                                    ink type, and location of the tattoo, as well as the patient's skin type. On
                                    average, it takes 6-10 sessions to achieve significant fading or complete removal.
                                </p>
                            </AccordionItem>
                            <!-- FQA 4 -->
                            
                            <!-- FQA 5 -->
                            <AccordionItem title="How long should I wait between sessions?">
                                <p>
                                    To allow your body enough time to heal and absorb the fragmented ink particles, it
                                    is recommended to wait 6-8 weeks between laser tattoo removal sessions.
                                </p>
                            </AccordionItem>
                            <!-- FQA 6 -->
                            <AccordionItem title="Can all tattoo colors be removed?">
                                <p>
                                    Most tattoo colors can be removed, but some colors are more challenging than others.
                                    Black and dark blue inks are generally the easiest to remove, while colors like
                                    green, yellow, and light blue might require more sessions and specialized lasers.
                                    Complete removal of some colors may not always be possible, but significant fading
                                    can often be achieved.
                                </p>
                            </AccordionItem>
                            <!-- FQA 7 -->
                           
                            <!-- FQA 8 -->
                            <AccordionItem title="Will there be any scarring after the tattoo removal process?">
                                <p>
                                    Scarring is a rare occurrence when the procedure is performed by an experienced
                                    professional using the proper laser technology. However, following aftercare
                                    instructions is crucial to minimize the risk of scarring and other complications.
                                </p>
                            </AccordionItem>
                            <!-- FQA 9 -->
                            <AccordionItem title="Can I remove only a part of my tattoo or lighten it for a cover-up?">
                                <p>
                                    Yes, laser tattoo removal can be used to target specific areas of a tattoo or to
                                    lighten the tattoo, making it easier to cover up with new ink. It's important to
                                    discuss your goals with the tattoo removal specialist to create a customized
                                    treatment plan.
                                </p>
                            </AccordionItem>
                            <!-- FQA 10 -->
                        
                            <!-- Add more AccordionItem components for other FAQ items -->
                        </div>


                    </div>
                </div>
                <div class="p-3">
                    <img src="@/assets/img/faq/faq_image.png" alt="FAQ Image" class="" />
                </div>
            </div>

            <!-- FAQ Section -->


        </section>

        <!-- Client Results Section -->


        <!-- Book Consultation button -->
        <section class="text-center my-12">
            <router-link to="/contact-us" class="bg-brand-primary text-white font-bold py-3 px-6 w-64 rounded">
                BOOK A CONSULTATION
            </router-link>
        </section>
    </div>
</template>
  
<script>
import AccordionItem from '@/components/AccordionItem.vue';

export default {
    components: {
        AccordionItem,
    },
};
</script>