<template>
    <div>
      <button
        type="button"
        class="flex items-center justify-between w-full p-5 text-left text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 font-medium border dark:border-gray-700 border-gray-200 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800"
        @click="toggle"
      >
        <span>{{ title }}</span>
        <svg
          class="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            :d="open ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <div v-if="open" class="p-5 border border-gray-200 dark:border-gray-700 border-t-0">
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        open: false,
      };
    },
    methods: {
      toggle() {
        this.open = !this.open;
      },
    },
  };
  </script>
  